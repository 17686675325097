.site-footer {
  padding: 160px 0 180px;

  @include media('<=tablet') {
    padding: 5% 0;
  }

  &__logo {
    vertical-align: middle;
    display: flex;
    align-items: center;
    margin-bottom: 65px;

    @include media('<=tablet') {
      display: block;
      text-align: center;
    }

    img {
      height: 75px;
      margin-right: 100px;

      @include media('<=tablet') {
        display: block;
        margin: 0 auto 30px;
      }
    }

    span {
      font-family: $RobotoMono;
      font-size: 40px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $white;

      @include media('<=tablet') {
        width: 100%;
        font-size: 30px;
        display: block;
      }
    }
  }

  .center {
    display: flex;
    justify-content: space-between;

    @include media('<=tablet') {
      display: block;
    }
  }

  &__block {
    width: 48%;
    display: inline-block;

    @include media('<=tablet') {
      width: 100%;
      padding: 5%;
    }

    h3 {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.88;
      letter-spacing: normal;
      color: $white;

      @include media('<=tablet') {
        text-align: center;
      }
    }

    ul {
      list-style: none;
      width: 100%;
      text-align: right;
      padding-top: 23px;

      @include media('<=tablet') {
        text-align: center;
      }

      li {
        padding: 0 0 0 5px;
        position: relative;
        z-index: 1;
        display: inline-block;

        a {
          transition: 500ms ease;

          svg {
            width: 30px;
            height: auto;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

  }
}
