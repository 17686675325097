$white: #ffffff;
$black: #000000;
$dark: #101010;
$purple: #5f4c98;
$orange: #eb9c3f;
$green: #70C9BE;

.background--purple {
  background-color: $purple;
}

.background--black {
  background-color: $black;
}

.background--orange {
  background-color: $orange;
}

.background--green {
  background-color: $green;
}

$default: 'Roboto',
sans-serif;
$RobotoMono: 'Roboto Mono',
monospace;

.button {
  width: 220px;
  height: 50px;
  transform: rotate(-3deg);
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 47px;
  letter-spacing: normal;
  text-align: center;
  color: $white;
  font-family: $RobotoMono;
  display: block;
  transition: 500ms ease;
  text-transform: uppercase;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 3px $white;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: 100%;
    bottom: -5px;
    left: -5px;
    background: $white;
    z-index: -1;
    transition: 500ms ease;
  }

  &:hover {
    transform: rotate(0);
    color: $purple;

    &:after {
      right: -5px;
    }
  }

  &--black {
    &:hover {
      color: $black;
    }
  }
}
