.intro {
  width: 100%;
  height: 100vh;
  background-size: auto 90%;
  background-position: right center;
  background-repeat: no-repeat;

  @include media('<=tablet') {
    background-position: center center;
  }
}

.intro-content {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  @include media('<=tablet') {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  &__image {
    width: 100%;
    max-width: 690px;
    margin: 0 auto 100px;
    text-align: center;

    &--title {
      max-width: 100%;
    }

    img {
      width: 100%;
    }

    h1 {
      font-family: $RobotoMono;
      font-size: 120px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $white;
      margin: 0;
      margin-bottom: 100px;
      position: relative;
      transform: rotate(-4deg);

      @include media('<=tablet') {
        font-size: 70px;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 50%;
        height: 3px;
        width: 60px;
        transform: translateX(-50%) rotate(-4deg);
        background: $white;
      }
    }
  }

  &__text {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;

    h3 {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }
  }
}

.about-block {
  height: 100vh;

  @include media('<=tablet') {
    height: auto;
    padding: 50px 0 150px;
  }

  .center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;

    @include media('<=tablet') {
      display: block;
      height: auto;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      padding: 0;
    }
  }

  &__overlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 800px;
    left: 50px;

    @include media('<=tablet') {
      max-width: 90%;
      left: 5%;
      right: 5%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }

  &__block {
    width: 49%;
    position: relative;

    @include media('<=tablet') {
      width: 100%;
      display: block;
    }

    h2 {
      max-width: 420px;
      font-family: $RobotoMono;
      font-size: 50px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $white;
      margin-bottom: 100px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -50px;
        height: 3px;
        width: 60px;
        transform: rotate(-5deg);
        background: $white;
      }

      @include media('<=tablet') {
        font-size: 40px;
        max-width: 100%;
      }
    }

    p {
      max-width: 575px;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: $white;
      margin-bottom: 60px;

      @include media('<=tablet') {
        max-width: 100%;
      }
    }
  }
}

.what-we-do {
  width: 100%;
  height: 100vh;
  background-color: $black;
  background-size: auto 80%;
  background-position: center center;
  background-repeat: no-repeat;

  @include media('<=tablet') {
    height: auto;
    padding: 50px 0;
  }

  .center {
    height: 100vh;
    background-size: 400px auto;
    background-position: 0px center;
    background-repeat: no-repeat;

    @include media('<=tablet') {
      width: 90%;
      padding: 0;
      padding-top: 200px;
      background-size: 100% auto;
      background-position: 0 top;
      margin-left: 5%;
      margin-right: 5%;
      height: auto;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    //Last added
    flex-wrap: wrap;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    //End of last added
    justify-content: space-between;

    @include media('<=tablet') {
      display: block;
      //Last added
      top: auto;
      transform: translateY(0);
      //End of last added
    }

    li {
      //width: 24%;
      //height: 100vh;
      width: 50%;

      @include media('<=tablet') {
        width: 100%;
        height: auto;
        display: block;
        margin-bottom: 60px;
        text-align: left !important;
        padding: 0 !important;
      }

      .button {
        opacity: 0;
        visibility: hidden;

        @include media('<=tablet') {
          opacity: 1;
          visibility: visible;
        }
      }

      &:hover {
        .button {
          opacity: 1;
          visibility: visible;
        }
      }

      .content {
        position: relative;
        //top: 50%;
        //transform: translateY(-50%);
        display: inline-block;

        @include media('<=tablet') {
          top: auto !important;
          text-align: left !important;
          transform: translateY(0) !important;
        }
      }

      &:first-of-type {
        margin-bottom: 60px;

        .content {
          //top: 20%;
        }
      }

      &:nth-of-type(2) {
        text-align: right;
        margin-bottom: 60px;

        .content {
          text-align: left;
          //top: 55%;
        }
      }

      &:nth-of-type(3) {
        padding-left: 20%;

        .content {
          //top: 30%;
        }
      }

      &:nth-of-type(4) {
        text-align: right;
        padding-right: 20%;

        .content {
          text-align: left;
          //top: 60%;
        }
      }
    }
  }

  h3 {
    font-family: $RobotoMono;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $white;
    margin-bottom: 70px;
    position: relative;

    @include media('<=tablet') {
      margin-top: 0;
      margin-bottom: 30px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -15px;
      height: 3px;
      width: 60px;
      transform: rotate(-5deg);
      background: $white;
    }

    a {
      color: $white;
    }
  }
}

.news {
  height: 100vh;

  @include media('<=tablet') {
    height: auto;
    padding: 150px 0 150px;
  }

  &__title {
    position: absolute;
    width: 360px;
    top: -20px;

    @include media('<=tablet') {
      position: relative;
      top: 0;
      width: 80%;
      margin-bottom: 50px;
    }
  }

  &__read-all {
    width: 100%;
    text-align: left;
    font-family: $RobotoMono;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;

    a {
      color: $white;
      transition: 500ms ease;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        height: 3px;
        right: 100%;
        background: $white;
        transition: 500ms ease;
      }

      &:hover {
        &::before {
          right: 0;
        }
      }
    }
  }

  .center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @include media('<=tablet') {
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
      padding: 0;
      top: auto;
      transform: translateY(0);
    }
  }

  &--page {
    height: auto;
    padding-bottom: 200px;

    .center {
      height: auto;
      top: auto;
      transform: translateY(0);
    }
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include media('<=tablet') {
      display: none !important;
    }

    &--left {
      left: -100px;
    }

    &--right {
      right: -100px;
    }

    svg {
      height: 50px;
      width: auto;

      path {
        fill: $white;
      }
    }

    &--rotate {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .single-post {

    @include media('<=tablet') {
      margin-bottom: 50px;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include media('<=tablet') {
        display: block;
        margin-bottom: 100px;
      }
    }

    &__left {
      height: 400px;
      width: 45%;
      position: relative;

      @include media('<=tablet') {
        width: 100%;
        margin: 0 0 30px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__right {
      width: 45%;
      margin-right: 2.5%;
      padding: 5% 0;

      @include media('<=tablet') {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      h2 {
        margin-bottom: 100px;
        font-family: $RobotoMono;
        font-size: 50px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: $white;
        position: relative;
        max-width: 420px;

        @include media('<=tablet') {
          font-size: 40px;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -50px;
          height: 3px;
          width: 60px;
          transform: rotate(-5deg);
          background: $white;
        }
      }

      p {
        max-width: 575px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: $white;
        margin-bottom: 60px;
      }
    }
  }
}
