.page-intro {
  padding-top: 330px;
  padding-bottom: 90px;
  width: 100%;

  &__inner {
    max-width: 825px;
    margin: 0 auto;

    @include media('<=tablet') {
      max-width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  }

  h2 {
    margin: 0;
    font-family: $RobotoMono;
    font-size: 50px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: $white;
    position: relative;
    max-width: 420px;
  }

  @include media('<=tablet') {
    padding-top: 200px;

    h2 {
      max-width: 100%;
      font-size: 40px;
    }
  }
}

.news__nav {
  text-align: center;
  display: inline-block;
  width: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  padding-top: 100px;

  ul {
    display: flex;

    li {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 2px $white;
      border-right: none;
      margin-right: 0 !important;

      &:nth-last-child(2) {
        border-right: solid 2px $white;
      }

      &.previous-button {
        border: none;
        margin-right: 15px !important;
      }

      &.next-button {
        border: none;
        margin-left: 15px;
      }

      a {
        width: 100%;
        height: 100%;
        display: block;
        line-height: 49px;
        height: 50px;
        transition: 500ms ease;
        color: $white;
        text-align: center;

        svg {
          position: relative;
          top: 50%;
          transform: translateY(-50%);

          path {
            transition: 500ms ease;
            fill: $white;
          }
        }

        &:hover {
          opacity: 0.5;

          svg {
            path {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.news__nav ul .active a,
.news__nav ul .active a:hover {
  color: $black;
  background: $white;
  opacity: 1;
}
