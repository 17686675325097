.post-intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include media('<=tablet') {
    display: block;
  }

  &__left {
    height: 600px;
    width: 45%;
    position: relative;

    @include media('<=tablet') {
      width: 100%;
      height: 45vh;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__right {
    width: 45%;
    margin-right: 2.5%;
    padding: 5% 0;

    @include media('<=tablet') {
      width: 100%;
      margin: 0;
      padding: 5%;
    }

    h2 {
      margin-bottom: 100px;
      font-family: $RobotoMono;
      font-size: 50px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: $white;
      position: relative;
      max-width: 420px;

      @include media('<=tablet') {
        max-width: 100%;
        font-size: 40px;
        margin: 0;
      }
    }

  }
}

.post-content {
  padding-top: 150px;
  padding-bottom: 290px;

  @include media('<=tablet') {
    padding: 70px 0 130px;
  }

  &__inner {
    max-width: 825px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $white;

    @include media('<=tablet') {
      max-width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  }

  p,
  li {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: $white;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  b,
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  form {
    max-width: 640px;
    margin: 0 auto;

    span.wpcf7-form-control-wrap {
      width: 100%;
      display: block;
    }

    span.button {
      &:hover {
        input {
          color: $black;
        }
      }
    }
  }

  input[type="submit"] {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 47px;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    font-family: $RobotoMono;
    border: 0;
    background: transparent;
    text-transform: uppercase;
    transition: 500ms ease;
    width: 100%;
    padding: 0;
    cursor: pointer;
  }

  input[type="text"],
  input[type="email"] {
    height: 50px;
    line-height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    background: $dark;
    border: 0;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $white;
    width: 100%;
    margin-top: 10px;
  }

  textarea {
    min-height: 200px;
    min-width: 100%;
    max-width: 100%;
    padding: 10px;
    background: $dark;
    border: 0;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: $white;
    margin-top: 10px;
  }

  a {
    color: $purple;
    transition: 500ms ease;

    &:hover {
      color: $white;
    }
  }
}
