.header {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 150px;

  @include media('<=tablet') {
    top: 10px;
    padding: 0 10px;
  }
}

.header__logo a img {
  display: inline-block;
  width: auto;
  max-height: 70px;

  @include media('<=tablet') {
    max-height: 40px;
  }
}

.header__menu {
  position: fixed;
  right: 150px;
  height: 21px;
  width: 50px;
  text-indent: -99999px;
  border-top: 3px solid $white;
  z-index: 20;
  transition: 500ms ease;
  cursor: pointer;

  @include media('<=tablet') {
    right: 10px;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    background-color: $white;
    height: 3px;
    transition: 500ms ease;
  }

  &:before {
    top: 6px;
    left: 12px;
  }

  &:after {
    bottom: 0px;
  }

  &:hover {
    &:before {
      left: 0;
    }
  }
}

.header--active {
  .header__menu {
    border-color: transparent !important;
    height: 26px;

    &:before {
      left: 0;
      transform: rotate(135deg);
      top: 9px;
    }

    &:after {
      transform: rotate(-135deg);
      bottom: 11px;
    }
  }
}


.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 10;
  transition: 500ms ease;
  visibility: hidden;
  opacity: 0;
  transform: translate(100%, 0);
  padding-top: 45px;
  text-align: center;
}

.header--active .nav {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}

.nav__background {
  position: absolute;
  z-index: -1;
  top: 180px;
  left: 0;
  max-width: 800px;

  @include media('<=tablet') {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  img {
    width: 100%;
  }
}

.nav__inner {
  position: relative;
  z-index: 1;
  height: 100%;
}

.nav__logo {
  position: absolute;
  left: 0;
  top: 0;

  @include media('<=tablet') {
    top: -35px;
    left: 10px;
  }

  img {
    width: 70px;
    height: auto;

    @include media('<=tablet') {
      width: 30px;
    }
  }
}

.nav__social {
  position: absolute;
  bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;

  @include media('<=tablet') {
    bottom: 10px;
    left: 10px;
  }

  li {
    padding: 0 5px;
    position: relative;
    z-index: 1;

    a {
      display: flex;
      align-items: center;
      transition: 500ms ease;

      svg {
        width: 30px;
        height: auto;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.nav__menu {
  ul {
    list-style: none;
    margin: 0;
    padding: 100px 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 70%;
      margin-left: 15%;
      margin-right: 15%;
      padding: 20px 0;

      a {
        position: relative;
        font-family: $RobotoMono;
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $white;
        display: inline-block;
        position: relative;
        transition: 500ms ease;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -10px;
          height: 3px;
          width: 30px;
          transform: rotate(-5deg);
          background: $white;
          transition: 500ms ease;
        }

        &:hover {
          opacity: 0.7;

          &::before {
            transform: rotate(0);
          }
        }
      }

      &:first-of-type {
        text-align: right;
        padding-right: 50px;
      }

      &:nth-of-type(2) {
        text-align: left;
      }

      &:nth-of-type(4) {
        text-align: left;
        padding-left: 150px;
        padding-top: 100px;
      }

      &:nth-of-type(5) {
        text-align: right;
        margin-top: -50px;
      }

      @include media('<=tablet') {
        width: 100%;
        text-align: center !important;
        padding: 20px 0 !important;
        margin: 0 !important;
      }
    }
  }
}
